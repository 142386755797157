import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-toastify';
import { RiCreativeCommonsLine, RiCreativeCommonsByLine, RiCreativeCommonsNcLine, RiCreativeCommonsSaLine } from 'react-icons/ri';

import { ReactComponent as Logo } from './../assets/img/logo.svg';

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      width: 1200,
      widthColor: false,
      column: 0,
      columnColor: false,
      space: 0,
      spaceColor: false,
      padding: 0,
      paddingColor: false,
      grid: [],
      percent: 0,
      total: 0,
      pixel: 0,
      firstLoad: true,
      flagChange: false
    }

    this.copy = 2024;
    this.today = new Date();
    
    this.maxColumn = 16;
    this.maxSpace = 30;
    this.maxPadding = 60;
    this.maxWidth = 2000;
    
    // this.columnRef = React.createRef();

    this.inputChange = this.inputChange.bind(this);
    this.inputFocusEnter = this.inputFocusEnter.bind(this);
    this.inputFocusOut = this.inputFocusOut.bind(this);
    this.generateGrid = this.generateGrid.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount(){
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.column !== prevState.column){
      if(this.state.column > this.maxColumn){
        this.setState({ column: this.maxColumn });
        toast.error(() => (<>Maximum Column Exceed<span>Maximum number of column is <strong>{this.maxColumn}</strong></span></>));
      }else{
        this.generateGrid();
      }
    }

    if(this.state.space !== prevState.space){
      if(this.state.space > this.maxSpace){
        this.setState({ space: this.maxSpace });
        toast.error(() => (<>Maximum Gutter Exceed<span>Maximum number of gap is <strong>{this.maxSpace}</strong></span></>));
      }else{
        this.handleResize();
      }
    }

    if(this.state.padding !== prevState.padding){
      if(this.state.padding > this.maxPadding){
        this.setState({ padding: this.maxPadding });
        toast.error(() => (<>Maximum Margin Exceed<span>Maximum number of margin is <strong>{this.maxPadding}</strong></span></>));
      }else{
        this.handleResize();
      }
    }

    if(this.state.width !== prevState.width){
      if(this.state.width > this.maxWidth){
        this.setState({ width: this.maxWidth });
        toast.error(() => (<>Maximum Width Exceed<span>Maximum number of width is <strong>{this.maxWidth}</strong></span></>));
      }else{
        this.handleResize()
      }
    }

    if(this.state.grid !== prevState.grid){
      this.handleResize();
    }
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
  }

  inputChange(value, event){
    if(typeof event.event !== 'undefined'){
      this.setState({ [event.event.target.name]: value.floatValue });

      if(this.state.firstLoad){
        this.setState({ firstLoad: false });
      }
    }
  }

  inputFocusEnter(event){
    this.setState({ [`${event.target.name}Color`]: true });
  }
  
  inputFocusOut(event){
    this.setState({ [`${event.target.name}Color`]: false });
  }

  generateGrid(){
    let grid = [],
        percent = 0;

    for(let i = 1; i <= this.state.column; i++){
      grid.push(i)
    }

    percent = 100 / grid.length;

    this.setState({
      grid: grid,
      percent: percent
    });
  }
  
  handleResize(){
    if(this.state.firstLoad){
      let landscape = true,
          tablet = false,
          mobile = false,
          rezColumn = 12,
          rezSpace = 20,
          rezPadding = 40;

      if(window.innerWidth < window.innerHeight){
        landscape = false;
      }
  
      if(window.innerWidth <= 767){
        tablet = false;
        mobile = true;
      }else if(window.innerWidth >= 768 && window.innerWidth <= 1024){
        tablet = true;
        mobile = false;
      }

      if(mobile){
        rezSpace = 10;
        rezPadding = 20;

        if(landscape){
          rezColumn = 4;
        }else{
          rezColumn = 2;
        }
      }else if(tablet){
        rezSpace = 15;
        rezPadding = 30;

        if(landscape){
          rezColumn = 8;
        }else{
          rezColumn = 6;
        }
      }

      this.setState({
        column: rezColumn,
        space: rezSpace,
        padding: rezPadding,
      });
    }
    
    const colOne = document.getElementById('column-1');

    if(colOne !== null){
      this.setState({
        total: '',
        pixel: ''
      });

      setTimeout(() => {
        this.setState({
          total: document.getElementById('the-total').clientWidth,
          pixel: colOne.offsetWidth
        });
      }, 300);
    }
  }

  renderAkhir(d){
    if (d > 3 && d < 21) return 'th';

    switch (d % 10) {
      case 1:  return 'st';
      case 2:  return 'nd';
      case 3:  return 'rd';
      default: return 'th';
    }
  }

  render(){
    const floatPercent = parseFloat((this.state.percent).toFixed(2));

    return(
      <>
        <Helmet>
          <title>ScreenGrid {`${this.state.grid.length} grid • ${this.state.pixel}px • ${floatPercent}%`}</title>
        </Helmet>

        <header>
          <div>
            <a href="/">
              <Logo />
            </a>
            <ul>
              <li>
                <label>Column</label>
                {/* <input type="text" name="column" placeholder="Column" value={this.state.column} onChange={(value, event) => this.state.firstLoad ? this.inputChange(event, true) : this.inputChange(event)} /> */}
                <NumericFormat
                  name="column"
                  placeholder="Column"
                  thousandSeparator="."
                  decimalSeparator=","
                  value={this.state.column}
                  allowNegative={false}
                  onValueChange={this.inputChange}
                  onFocus={this.inputFocusEnter}
                  onBlur={this.inputFocusOut}
                  onMouseOver={this.inputFocusEnter}
                  onMouseOut={this.inputFocusOut}
                  className="no-unit"
                  // isAllowed={(value) => {
                  //   return value.value === '' || value.floatValue <= this.maxColumn;
                  // }}
                  pattern="[0-9]*"
                />
              </li>
              <li>
                <label>Gutter</label>
                {/* <input type="text" name="space" placeholder="Gap" value={this.state.space} onChange={(event) => this.state.firstLoad ? this.inputChange(event, true) : this.inputChange(event)} /> */}
                <NumericFormat
                  name="space"
                  placeholder="Gutter"
                  thousandSeparator="."
                  decimalSeparator=","
                  value={this.state.space}
                  allowNegative={false}
                  onValueChange={this.inputChange}
                  onFocus={this.inputFocusEnter}
                  onBlur={this.inputFocusOut}
                  onMouseOver={this.inputFocusEnter}
                  onMouseOut={this.inputFocusOut}
                  // isAllowed={(value) => {
                  //   return value.value === '' || value.floatValue <= this.maxSpace;
                  // }}
                  pattern="[0-9]*"
                />
                <span className="unit">px</span>
              </li>
              <li>
                <label>Margin</label>
                {/* <input type="text" name="padding" placeholder="Padding" value={this.state.padding} onChange={(event) => this.state.firstLoad ? this.inputChange(event, true) : this.inputChange(event)} /> */}
                <NumericFormat
                  name="padding"
                  placeholder="Margin"
                  thousandSeparator="."
                  decimalSeparator=","
                  value={this.state.padding}
                  allowNegative={false}
                  onValueChange={this.inputChange}
                  onFocus={this.inputFocusEnter}
                  onBlur={this.inputFocusOut}
                  onMouseOver={this.inputFocusEnter}
                  onMouseOut={this.inputFocusOut}
                  // isAllowed={(value) => {
                  //   return value.value === '' || value.floatValue <= this.maxSpace;
                  // }}
                  pattern="[0-9]*"
                />
                <span className="unit">px</span>
              </li>
              <li>
                <label>Max. Container Width</label>
                {/* <input type="text" name="width" placeholder="Width" value={this.state.width} onChange={(event) => this.state.firstLoad ? this.inputChange(event, true) : this.inputChange(event)} /> */}
                <NumericFormat
                  name="width"
                  placeholder="Width"
                  thousandSeparator="."
                  decimalSeparator=","
                  value={this.state.width}
                  allowNegative={false}
                  onValueChange={this.inputChange}
                  onFocus={this.inputFocusEnter}
                  onBlur={this.inputFocusOut}
                  onMouseOver={this.inputFocusEnter}
                  onMouseOut={this.inputFocusOut}
                  // isAllowed={(value) => {
                  //   return value.value === '' || value.floatValue <= this.maxSpace;
                  // }}
                  pattern="[0-9]*"
                />
                <span className="unit">px</span>
              </li>
            </ul>
          </div>
        </header>

        <div className={`the-grid ${this.state.widthColor ? 'color-width' : ''}`} id="the-total" style={{ maxWidth: this.state.width ? `${this.state.width}px` : 'none', padding: `0 ${this.state.padding ? this.state.padding : 0}px`}}>
          <div className={`mar-left ${this.state.paddingColor ? 'color-mar' : ''} ${this.state.widthColor ? 'color-hide' : ''}`} style={{ width: `${this.state.padding}px` }}></div>
          <div className={`mar-right ${this.state.paddingColor ? 'color-mar' : ''} ${this.state.widthColor ? 'color-hide' : ''}`} style={{ width: `${this.state.padding}px` }}></div>
          <div className="pixel">
            <ul style={{margin: `0 ${((this.state.padding ? this.state.padding : 0) - ((this.state.space ? this.state.space / 2 : 0)))}px`}}>
              {this.state.grid.map((value, index) => index > 0 ? (
                <li key={`pixel-${value}`} style={{ width: `${this.state.pixel ? this.state.percent * index : 0}%`, padding: `0 ${(this.state.space ? this.state.space / 2 : 0)}px`}}>
                  <div>
                    <span>{value - 1}</span>
                    {this.state.pixel ? (
                      <>
                        <strong>{(this.state.pixel * index) + ((this.state.space ? this.state.space : 0) * (index - 1))}</strong>px
                      </>
                    ) : (
                      <>
                        &nbsp;
                      </>
                    )}
                  </div>
                </li>
              ) : '')}
              <li style={{ width: `${this.state.pixel ? this.state.percent * this.state.grid.length : 0}%`, padding: `0 ${this.state.space ? this.state.space / 2 : 0}px`}}>
                <div>
                  <span>{this.state.grid.length}</span>
                  {this.state.pixel ? (
                    <>
                      <strong>{this.state.padding === 0 ? this.state.total : (this.state.pixel * this.state.grid.length) + (this.state.space * (this.state.grid.length - 1))}</strong>px
                    </>
                  ) : (
                    <>
                      &nbsp;
                    </>
                  )}
                </div>
              </li>
            </ul>
            <div style={{width: this.state.total ? '100%' : '0%'}}>
              <span>Container Width</span>
              {this.state.total ? (
                <>
                  <strong>{this.state.total}</strong>px
                </>
              ) : (
                <>
                    &nbsp;
                </>
              )}
            </div>
          </div>
          <ul className={`column ${this.state.columnColor ? 'color-column' : ''} ${this.state.spaceColor ? 'color-gutter' : ''}`} style={{margin: `0 ${-(this.state.space ? this.state.space / 2 : 0)}px`}}>
            {this.state.grid.map((value, index) => (
              <li key={`column-${value}`} style={{ width: `${this.state.percent}%`, padding: `0 ${this.state.space ? this.state.space / 2 : 0}px`}}>
                <div id={`column-${value}`}>
                  <div style={{fontSize: this.state.pixel < 100 ? `${36 * (this.state.pixel / 100)}px` : '36px', lineHeight: this.state.pixel < 100 ? `${36 * (this.state.pixel / 100)}px` : '36px'}}>
                    {value}<sup style={{top: this.state.pixel < 100 ? `-${12 * (this.state.pixel / 100)}px` : '-12px', fontSize: this.state.pixel < 100 ? `${20 * (this.state.pixel / 100)}px` : '20px', lineHeight: this.state.pixel < 100 ? `${20 * (this.state.pixel / 100)}px` : '20px'}}>{this.renderAkhir(value)}</sup>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <footer>
          <a href="/">ScreenGrid</a> &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} by <a href="https://nabilamerthabit.com" target="_blank" rel="noreferrer">Nabil Amer Thabit</a> is licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer">CreativeCommons BY-NC-SA 4.0 <RiCreativeCommonsLine /> <RiCreativeCommonsByLine /> <RiCreativeCommonsNcLine /> <RiCreativeCommonsSaLine /></a>
        </footer>
      </>
    );
  }
}

export default App;